import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json' // for all requests
axios.defaults.validateStatus = false;

const api_url = process.env.VUE_APP_ROOT_API + 'api/';
const second_server = "https://inscricoesapi.tkx.com.br/"+ 'api/';

export default {
    name: 'Events',
    get_events() {
        return axios.get(api_url + 'events');
    },
    get_destaques() {
        return axios.get(api_url + 'events/destaques');
    },
    get_event(slug){
        return axios.get(api_url + 'event/'+slug);
    },
    get_regulamento(slug){
        return axios.get(api_url + 'event/'+slug+'/regulamento');
    },
    get_lotes(event_id,type,token){
        return axios.get(second_server + 'auth/lotes/'+event_id+'/'+type,{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_categorias(event_id,token){
        return axios.get(second_server + 'auth/categorias/'+event_id,{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_kits(lot_id,token){
        return axios.get(second_server + 'auth/kits/'+lot_id,{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_agendamentos(event_id,token){
        return axios.get(second_server + 'auth/agendamento/'+event_id,{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_status(event){
        let today = new Date();
        let start_date = new Date(event.subscription_start);
        let end_date = new Date(event.subscription_end);
        if (start_date <= today && end_date >= today) {
            return '<span class="pull-right small insc-aberta">Inscrições abertas</span>';
        } else if(start_date > today) {
            return '<span class="pull-right small insc-embreve">Em Breve</span>';
        }else{
            return '<span class="pull-right small insc-encerrada">Inscrições encerradas</span>';
        }
    }
}
