import axios from 'axios';

const api_url = process.env.VUE_APP_ROOT_API + 'api/';

export default {
    name: 'Banners',
    get_banners() {
        return axios.get(api_url + 'banners', { validateStatus: false });
    },
    get_anuncios() {
        return axios.get(api_url + 'anuncios', { validateStatus: false });
    }
}
