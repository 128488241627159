<template>
  <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftkxoficial%2F&tabs&width=360&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1134653443679808"
          width="360"
          height="130"
          style="border:none;overflow:hidden;max-width: 100%"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">

  </iframe>
</template>

<script>
</script>

<style scoped>
</style>
