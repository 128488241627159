<template>
  <div>
    <p v-for="anuncio in anuncios" :key="anuncio.id">
      <a v-if="anuncio.link != ''" :href="anuncio.link" :title="anuncio.name" target="_blank">
        <img :src="anuncio.image" :alt="anuncio.name" style="width: 100%" />
      </a>
    </p>
  </div>
</template>

<script>
import Banners from "@/api/Banners";

export default {
  name: "Anuncios",
  data() {
    return {
      anuncios: [],
    };
  },
  mounted() {
    var anuncios = Banners.get_anuncios();

    anuncios.then((request) => {
      if (request.status == 200) {
        this.anuncios = request.data;
      }
    });
  },
};
</script>

<style>
</style>
