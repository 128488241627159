import axios from 'axios';

const api_url = process.env.VUE_APP_ROOT_API + 'api/';

export default {
    name: 'News',
    get_news() {
        return axios.get(api_url + 'news', { validateStatus: false });
    },
    get_news_slug(news_slug) {
        return axios.get(api_url + 'noticia' + '/' + news_slug, { validateStatus: false });
    }
}
