<template>
  <HeaderHome/>
  <BannerEventos/>
<!--  <BannersHome/>-->
  <FiltroEvento/>
  <slot></slot>
  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<style>

</style>

<script>

  import HeaderHome from '@/components/HeaderHome.vue'
  // import BannersHome from '@/components/BannersHome.vue'
  import FiltroEvento from '@/components/FiltroEvento.vue'
  import Footer from '@/components/Footer.vue'
  import FooterEnd from '@/components/FooterEnd.vue'
  import Newsletter from '@/components/Newsletter.vue'
  import BannerEventos from "@/components/BannerEventos";

  export default {
    components: {
      BannerEventos,
      HeaderHome,
      // BannersHome,
      FiltroEvento,
      Footer,
      FooterEnd,
      Newsletter
    }
  }
</script>
