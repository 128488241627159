<template>
  <div class="col-md-12 home-event-list">
    <h1 v-if="events.length">Eventos</h1>

    <div class="row">
      <div class="event col-md-4" v-for="event in events" :key="event.id">
        <v-link :title="event.name" :href="'/evento/' + event.id + '/' + event.slug + '/'">
          <div class="card">
            <div class="card-header" v-html="status(event)">
            </div>
            <img class="card-img-top" :src="event.image" alt="Card image">
            <div class="card-body">
              <h4 class="card-title text-center">{{ event.start_at.toUpperCase() }}</h4>
              <p class="card-text">{{ event.name }}</p>
            </div>
          </div>
        </v-link>
      </div>
    </div>
    <div class="row" v-if="eventoscount > 9">
      <div class="col-md-12 text-right">
        <a class="btn btn-danger" href="#">Veja mais</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr>
      </div>
    </div>

  </div>

  <div class="col-md-12 home-event-list concluidos"  v-if="eventsconcluidos.length">
    <h1 v-if="eventsconcluidos.length">Eventos Concluídos</h1>

    <div class="row">
      <div class="event col-md-4" v-for="event in eventsconcluidos" :key="event.id">
        <v-link :title="event.name" :href="'/evento/' + event.id + '/' + event.slug + '/'">
          <div class="card">
            <div class="card-header" v-html="status(event)">
            </div>
            <img class="card-img-top" :src="event.image" alt="Card image">
            <div class="card-body">
              <h4 class="card-title text-center">{{ event.start_at.toUpperCase() }}</h4>
              <p class="card-text">{{ event.name }}</p>
            </div>
          </div>
        </v-link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-right">
        <a class="btn btn-danger" href="#">Veja mais</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr>
      </div>
    </div>

  </div>
</template>

<script>
import VLink from "./VLink";
import Events from "../api/Events";

export default {
  name: "EventosHome",
  components: {
    VLink,
  },
  data() {
    return {
      eventoscount:0,
      eventsconcluidos:[],
      events: [
        /* {
           id: "73",
           inscricaoConclusao: "X",
           status: true, //Status Hj é calculador com a data de conclusão
           slug: "maratona-pedra-azul",
           name: "Maratona Pedra Azul",
           date: "07 | NOVEMBRO | 2021",
           image: "assets/img/eventos/logos/89447_Prancheta_4.png",
         },
         {
           id: "74",
           inscricaoConclusao: "X",
           status: false, //Status Hj é calculador com a data de conclusão
           slug: "maratona-pedra-azul",
           name: "Maratona Pedra Azul",
           date: "07 | NOVEMBRO | 2021",
           image: "assets/img/eventos/logos/89447_Prancheta_4.png",
         },
         {
           id: "45",
           inscricaoConclusao: "X",
           status: true, //Status Hj é calculador com a data de conclusão
           slug: "maratona-pedra-azul",
           name: "Maratona Pedra Azul",
           date: "07 | NOVEMBRO | 2021",
           image: "assets/img/eventos/logos/89447_Prancheta_4.png",
         },*/
      ],
    };
  },
  methods: {
    status(event) {
      return Events.get_status(event);
    },
    order_by_date(array){
      return array.sort(function(a,b){
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.start_event_at) - new Date(b.start_event_at);
      });
    }
  },
  mounted() {

    Events.get_events().then((request) => {
      if (request.status == 200) {
        if(request.data.length > 3){
          request.data = this.order_by_date(request.data);
          request.data.splice(9,request.data.length - 9)
          this.events = request.data;
        }else{
          request.data = this.order_by_date(request.data);
          this.events = request.data;
        }
      }
    });
  }
};
</script>

<style>
.concluidos {
  background-color: #f0a9294f;
}

.event .card .card-header {
  position: absolute;
  color: #fff;
  border-radius: 0px 0px 0px 0px;
  font-family: montserratregular, sans-serif;
}

.event .card img {
  width: 100%;
  aspect-ratio: 1.43;
}

.event .card {
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: 0px 2px 4px 0px #755250;
  border: 0px;
  margin-bottom: 15px;
  font-family: montserratregular, sans-serif;
}

.event .card:hover {
  transform: scale(1.02);
}

.event a {
  text-decoration: none;
}

.event .card h4 {
  color: #d31f28;
  font-family: montserratbold, sans-serif;
  font-weight: bold;
}

.event .card p {
  font-size: 14px;
  color: #333;
  text-decoration: none;
  font-family: MontserratLight, sans-serif;
  padding: 3px 0 5px 0;
}

.event .card .insc-aberta {
  position: initial;
}

.event .card p span {
  color: #d31f28;
}
</style>
