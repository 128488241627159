<template>
    <div class="container-fluid banner">
        <div class="row banner-box">
            <div class="col-md-12">
                <div class="carousel slide" data-ride="carousel">
                    <!-- Indicators -->
                    <ol v-if="bannersLength > 1" class="carousel-indicators">
                        <li @click.prevent="goto_slide(index)" :class="banner.classBullet" v-for="(banner , index) in banners" :key="banner.id"></li>
                    </ol>

                    <!-- Wrapper for slides -->
                    <div class="carousel-inner" role="listbox">
                        <div :class="banner.classSlider" v-for="banner in banners" :key="banner.id">
                          <a v-if="banner.link != ''" :href="banner.link" :title="banner.name" target="_blank">
                            <img :src="banner.image" :alt="banner.name">
                          </a>
                          <img v-if="banner.link == ''" :src="banner.image" :alt="banner.name">
                        </div>
                    </div>

                    <!-- Controls -->
                    <a v-if="bannersLength > 1" class="left carousel-control" @click.prevent="prev" href="#prev" role="button" data-slide="prev">
                        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                        <span class="sr-only">Anterior</span>
                    </a>
                    <a v-if="bannersLength > 1" class="right carousel-control" @click.prevent="next" href="#next" role="button" data-slide="next">
                        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                        <span class="sr-only">Próximo</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Banners from '@/api/Banners';

export default {
  name:"BannerHome",
  data() {
    return {
      defaultTimerSlider:8000,
      timerInterval: "",
      timerRunning:false,
      currentSlider:0,
      bannersLength:0,
      classNext:'next',
      classPrev:'prev',
      classDirLeft:'left',
      classDirRight:'right',
      banners: []
    };
  },
  methods:{
    next(){
      var toShowSlide = (this.currentSlider + 1);

      if((this.currentSlider + 1) > (this.bannersLength -1)){
        toShowSlide = 0;
        this.go_from_to(this.currentSlider,toShowSlide,this.classNext,this.classDirLeft);
      }else{
        this.go_from_to(this.currentSlider,toShowSlide,this.classNext,this.classDirLeft);
      }
    },
    prev(){
      var toShowSlide = (this.currentSlider - 1);
      if((this.currentSlider - 1) < 0){
        toShowSlide = (this.bannersLength - 1);
        this.go_from_to(this.currentSlider,toShowSlide,this.classPrev,this.classDirRight);
      }else{
        this.go_from_to(this.currentSlider,toShowSlide,this.classPrev,this.classDirRight);
      }
    },
    goto_slide(slide){
      if(this.currentSlider == slide){
        return;
      }
      this.go_from_to(this.currentSlider,slide);
    },
    go_from_to(currentSlider,toSlide , classEF=null,classAc=null){
      var classEfect,classAction;

      if(classEF !=null && classAc!=null){
        classEfect = classEF;
        classAction = classAc;
      }else {
        if (currentSlider < toSlide) {
          classEfect = this.classNext;
          classAction = this.classDirLeft;
        } else {
          classEfect = this.classPrev;
          classAction = this.classDirRight;
        }
      }

      this.banners[toSlide].classSlider = "item "+  classEfect;
      setTimeout(()=>{
        this.banners[currentSlider].classSlider = "item active "+classAction;
        this.banners[toSlide].classSlider = "item "+  classEfect + ' ' +classAction;
        this.banners[currentSlider].classBullet = ""
        this.banners[toSlide].classBullet = "active";

        setTimeout(()=>{
          this.banners[currentSlider].classSlider = "item"
          this.banners[toSlide].classSlider = "item active";
          this.currentSlider = toSlide;
        },600);
      },100);
    },
    triggerSlider(){
      if(this.timerRunning == false){
        this.timerRunning = true;
        this.timerInterval = setInterval(()=> {
          this.next();
        },this.defaultTimerSlider);
      }
    }
  },
  mounted() {


    let banners = Banners.get_banners();

    banners.then((request) => {
      if (request.status == 200) {
        this.banners = request.data;

        this.bannersLength = this.banners.length;
        this.banners.map(banner => {
          banner.classSlider = "item"
          banner.classBullet = '';
        })
        //seta o primeiro slider como Ativo
        if (this.bannersLength) {
          this.banners[0].classSlider = "item active";
          this.banners[0].classBullet = "active";
          this.currentSlider = 0;
        }
        //

        if(!(this.bannersLength == 1 || this.bannersLength == 0 )){
          //Start Timer that Loop the Sliders//
          this.triggerSlider();
        }
      }
    });
  }
}

</script>

<style>

</style>
