<template>
  <main-layout>
    <Loader :show="loading"/>
    <div class="container-fluid body">
      <div class="container">
        <div class="row body-box">

          <div class="col-md-8">
            <div class="row">
              <EventosHome/>
            </div>
            <div class="row" v-if="noticias.length">
              <div class="col-md-12">
                <h1>Notícias</h1>
                <div class="row">

                  <div class="news-showcase col-md-4" v-for="noticia in noticias" :key="noticia.id" >
                    <v-link :title="noticia.title" :href="'/noticia/'+noticia.id +'/'+noticia.slug+'/'">
                      <div class="card">
                        <div class="card-header">{{ noticia.day }}<br /><b>{{ noticia.month.toUpperCase() }}</b></div>
                        <img class="card-img-top" :src="noticia.image" :alt="noticia.title">
                        <div class="card-body">
                          <h4 class="card-title">{{ noticia.title }}</h4>
                          <p class="card-text"></p>
                          <p class="text-right">Saiba mais <span>→</span></p>
                        </div>
                      </div>
                    </v-link>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4" style="margin-top: 45px;">
            <Anuncios/>
            <FacebookSide />
          </div>

        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import { defineComponent } from "vue";

import MainLayout from "@/layouts/Main.vue";
import FacebookSide from "@/components/FacebookSide";
import Noticias from "@/api/News";
import VLink from "@/components/VLink"
import EventosHome from "@/components/EventosHome";
import Anuncios from "@/components/Anuncios";
import Loader from "@/components/Loader";

export default defineComponent({
  name: "Home",
  components: {
    MainLayout,
    FacebookSide,
    VLink,
    EventosHome,
    Anuncios,
    Loader
  },
  data() {
    return {
      loading:false,
      noticias: [],
      events:[],
    };
  },
  created() {
    this.loading = true;
  },
  mounted() {
    var noticias = Noticias.get_news();

    noticias.then((request) => {
      if (request.status == 200) {
        this.noticias = request.data;

        this.loading=false;
      }
    });

  },
});
</script>

<style>
.news-showcase .card .card-header{
  position: absolute;
  background-color: rgba(84,40,38,.8);
  background: rgba(84,40,38,.8);
  color: #fff;
  border-radius: 5px 0px 0px 0px;
  font-family: montserratregular,sans-serif;
}

.news-showcase .card{
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: 0px 2px 4px 0px #755250;
  border: 0px;
  margin-bottom: 15px;
  font-family: montserratregular,sans-serif;
}
.news-showcase .card:hover {
  transform: scale(1.02);
}

.news-showcase a{
  text-decoration: none;
}
.news-showcase .card h4{
  color: #d31f28;
  font-family: montserratbold,sans-serif;
  font-weight: bold;
}


.news-showcase .card p {
  font-size: 14px;
  color: #333;
  text-decoration: none;
  line-height: 14px;
  font-family: MontserratLight,sans-serif;
  padding: 3px 0 5px 0 ;
}

.news-showcase .card p span {
  color: #d31f28;
}
.event .card{
  min-height: 265px;
  overflow: hidden;
}

</style>
